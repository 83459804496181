import React from 'react'

function Changelog() {
    return (
        <div class="container is-fluid pt-4">
            {/* v1.1.0 */}
            <h1 class="title is-2">v1.1.0</h1>
            <p class="subtitle is-5">The stealth update, plus some other changes</p>
            <hr />
            <ul>
                <li>
                    [+] Added preliminary changelog system
                </li>
                <li>
                    [+] Added a new landing page to deter people who don't know the true purpose of this website.
                </li>
                <li>
                    [+] Added a new property to pages (<code>state</code>) which tells the Gatsby template whether the page is finished or not.
                </li>
                <li>
                    [~] Slight design tweaks: deprioritize debug info (e.g. entry count), make table use full width of display
                </li>
            </ul>
        </div>
    )
}

export default Changelog